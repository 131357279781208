// MainPage/MainPage.js
import React from 'react';
import './mainpage.css'; // Update the filename here
import logo from '../../assets/codehidelogo1.png';

function MainPage() {
  return (
    <div className="main-page">
      <h1 className="heading">Rent Type: Your Ultimate Vehicle Rental Destination</h1>
      <div className="content">
        <p>Welcome to Rent Type, your premier platform for renting any type of vehicle for any occasion!</p>
        <p>Whether you're in need of a car for a weekend getaway, a van for moving day, or a motorcycle for a thrilling adventure, Rent Type has you covered.</p>
        <div className="info">
          <h2>About Us</h2>
          <p>At Rent Type, we're passionate about connecting vehicle owners with individuals in need of temporary transportation solutions. Our user-friendly platform allows you to:</p>
          <ul>
            <li>List Your Vehicle: Easily create a listing for your vehicle, specifying details such as make, model, year, and rental terms.</li>
            <li>Browse Available Vehicles: Explore a wide range of vehicles available for rent in your area, with options to filter by type, price, and location.</li>
            <li>Book with Confidence: Enjoy peace of mind knowing that all rentals are covered by our comprehensive insurance policy and backed by our support team.</li>
            <li>Drive Away: Pick up your chosen vehicle from the owner's location and hit the road with confidence, knowing that you're getting a reliable ride for your needs.</li>
          </ul>
          <p>Our mission is to provide a convenient and reliable platform for vehicle owners and renters alike, facilitating seamless transactions and fostering trust within our community. Whether you're a vehicle owner looking to earn extra income or a renter in need of flexible transportation options, Rent Type is here to make your experience smooth and hassle-free.</p>
        </div>
      </div>
    </div>
  );
}

export default MainPage;
